export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [0,2,3,4,5];

export const dictionary = {
		"/(root)": [15,[2]],
		"/(root)/analytics": [16,[2,3]],
		"/(root)/joessecretcorner": [17,[2,4]],
		"/(root)/locations": [18,[2,5]],
		"/(root)/locations/[locationId]": [19,[2,5,6],[,,7]],
		"/(root)/locations/[locationId]/campaigns/[...campaignParams]": [~20,[2,5,6,8],[,,7]],
		"/(root)/locations/[locationId]/contacts/[...contactParams]": [~21,[2,5,6],[,,7]],
		"/(root)/locations/[locationId]/devices/[...devicesParams]": [22,[2,5,6,9],[,,7]],
		"/(root)/locations/[locationId]/voice-apps": [~23,[2,5,6],[,,7]],
		"/(extra)/login": [~11],
		"/(extra)/logout": [~12],
		"/(extra)/oauth2/callback": [~13],
		"/(extra)/session-expired": [14],
		"/(root)/sessiondebug": [24,[2]],
		"/(root)/users": [25,[2,10]],
		"/(root)/users/roles": [~26,[2,10]],
		"/(root)/users/session-policies": [~27,[2,10]],
		"/(root)/webrtc": [28,[2]],
		"/(root)/webrtc/debug": [~29,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';